import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import SolidPurpleArrow from 'imgs/zj/arrow.up.solid.purple.png';
import SolidDotPurpleArrow from 'imgs/zj/arrow.up.dots.purple.png';
import TransparentArrow from 'imgs/zj/arrow.up.transparent.png';
import TransparentArrowDots from 'imgs/zj/arrow.up.dots.transparent.png';
const StyledWrapper = styled.div`
position:absolute;
z-index:1;
>img{
    width:100%;
}
`;
const Arrows = [
    SolidPurpleArrow,
    SolidDotPurpleArrow,
    TransparentArrow,
    TransparentArrowDots
];

// type:1 实心紫色 2 实心点点紫色 3 透明 4 透明点点;
export default function Title({ pos = {}, width = '4.5rem', type = 3 }) {
    let currPos = { top: 'auto', left: 'auto', right: 'auto', bottom: 'auto', ...pos };
    let idx = type - 1;
    const node = useRef(null);
    useEffect(() => {
        if (node) {
            let ele = node.current;
            ele.parentNode.style.position = 'relative';
        }
    }, []);
    return (
        <StyledWrapper style={{ width, ...currPos }} ref={node}>
            <img src={Arrows[idx] || Arrows[0]} alt="向上的箭头" />
        </StyledWrapper>
    );
}
