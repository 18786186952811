import React from 'react';
import styled, { keyframes } from 'styled-components';
import MsgIcon from 'imgs/common/icon.msg.png';
import TelIcon from 'imgs/common/icon.tel.png';
import WxIcon from 'imgs/common/icon.wx.png';
import QrImg from 'imgs/qr.jpg';

const TipsShow = keyframes`
 0% {
        opacity: .2;
        right: 70px
    }

    to {
        opacity: 1;
        right: 80px
    }
`;
const Wrapper = styled.div`
  position: fixed;
  right: 30px;
  top: 50%;
  margin-top: -110px;
  z-index: 100;
  width: 65px;
  height: 220px;
  transform: translateZ(0);
  .content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 80;
    width: 64px;
    height: 220px;
    background: #fff;
    box-shadow: 0 12px 15px 0 rgba(71, 71, 71, 0.1), 0 20px 60px 0 rgba(71, 71, 71, 0.11);
    border-radius: 25px;
    text-align: center;

    .icon {
      position: relative;
      padding-top: 17px;
      cursor: pointer;
      width: 65px;
      height: 65px;
      img {
        width: 100%;
      }
      .ico {
        width: 30px;
        margin: 0 auto;
        margin-bottom: 4px;
        &.wx {
          width: 32px;
        }
      }
      .txt {
        font-size: 12px;
        color: #474747;
      }
      .toast {
        display: none;
        position: absolute;
        top: 18px;
        right: 80px;

        height: 52px;
        line-height: 52px;
        text-align: center;
        animation: ${TipsShow} 0.25s ease-in 1 forwards;
        &.img {
          padding: 5px;
          top: -30px;
          right: 80px;
          width: 150px;
          height: 150px;
          background: #fff;
          > img {
            border-radius: 6px;
          }
        }

        border-radius: 4px;
        box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.06), 0 15px 40px 0 rgba(0, 0, 0, 0.12);
        .txt {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          > span {
            color: #fff;
            font-weight: bold;
          }
          &.blue {
            width: 185px;
            background: #428dff;
          }
          &.pink {
            width: 230px;
            background: #ffaf00;
          }
        }
        .rectangle {
          position: absolute;
          right: -8px;
          top: 50%;
          margin-top: -8px;
          width: 8px;
        }
      }
      &:hover .toast {
        display: flex;
      }
    }
  }
`;

const Contact = ({ visible = true, qr = QrImg }) => {
  return visible ? (
    <Wrapper>
      <ul className="content">
        <li className="icon">
          <img className="ico wx" src={WxIcon} alt="微信图标" />
          <p className="txt">服务号</p>
          <div className="toast img">
            <img src={qr} alt="服务号二维码" />
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAiBAMAAACgmpTJAAAAMFBMVEVMaXH////////////////////////////////////////////////////////////6w4mEAAAAD3RSTlMAC4IgzZxJ7fljMrbfZyKOoQnWAAAAXElEQVQY02NgwAsC4awvBjDW/zI4678CnPVbAMb6fwrO+jEBxvr/VQDG+u8MZ31PgLH+f4Kz/ivAWZ/hrL9w1k84qw9TxydMk50xXIBw1SlM1ytg+BLh80AGogAA+7V9QnzoBIUAAAAASUVORK5CYII="
              alt="右侧三角"
              className="rectangle"
            />
          </div>
        </li>
        <li className="icon">
          <img className="ico" src={TelIcon} alt="电话图标" />
          <p className="txt">电话咨询</p>
          <div className="toast">
            <div className="txt pink">
              <span>客服热线：400-666-7689</span>
            </div>

            <img
              className="rectangle"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAiBAMAAACgmpTJAAAAMFBMVEVMaXH/vAD/sAD/rwD/rwD/sQD/sgD/rwD/sAD/sAD/swD/rwD/sAD/sAD/tAD/rwCcoQIiAAAAD3RSTlMAC4IgzZxJ7fljMrbfZyKOoQnWAAAAXElEQVQY02NgwAsC4awvBjDW/zI4678CnPVbAMb6fwrO+jEBxvr/VQDG+u8MZ31PgLH+f4Kz/ivAWZ/hrL9w1k84qw9TxydMk50xXIBw1SlM1ytg+BLh80AGogAA+7V9QnzoBIUAAAAASUVORK5CYII="
            />
          </div>
        </li>
        <li className="icon">
          <a href="https://intelcc-user.icsoc.net?channelKey=8710ef4410817d56e0e314075be124b2&init=1">
            <img className="ico" src={MsgIcon} alt="短信图标" />
          </a>
          <p className="txt">在线咨询</p>
          <div className="toast">
            <div className="txt blue">
              <span>点击开始 在线咨询</span>
            </div>
            <img
              className="rectangle"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAeBAMAAADTIlMfAAAAMFBMVEVMaXFCjf9Tlv9Djv9Ij/9Djv9Djv9Cj/9Djv9Djv9Cj/9Djv9Cjv9Djf9Ej/9Cjf8STxqOAAAAD3RSTlMA+wuCIM2cSe1jMrbfZyIN/gYCAAAAXUlEQVQY02NggIMkOOurA4z1vx3O+mgAY/3/rQBj/T8FZ32cAGP9/6YAY/0PgbN+FMBY/4XhrP8GcNYXOOsvnPUTzpLD1PEZ0+QQDBcgXHUKw/UIH7Vj+hwRGnAAAPFpfPfqT/Q2AAAAAElFTkSuQmCC"
            />
          </div>
        </li>
      </ul>
    </Wrapper>
  ) : null;
};

export default Contact;
