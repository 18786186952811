import React from 'react';
import styled from 'styled-components';
import Section from './__Section';
import Title from './__Title';
import ArrowUp from './__ArrowUp';

import News from 'RootCommon/news';

const Wrapper = styled.div`
  position: relative;
  max-width: 96rem;
  margin: 0 auto;
  padding: 3.6rem 0 5.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .cards {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 4.2rem;
    z-index: 999;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
    .card {
      position: relative;
      border-radius: 2rem;
      width: 17.3rem;
      height: 19rem;
      box-shadow: 0px 8px 15px 1px rgba(56, 0, 95, 0.05);
      cursor: pointer;
      background-color: #fff;
      &:not(:last-child) {
        margin-right: 1.6rem;
      }
      @media (max-width: 768px) {
        margin-bottom: 2rem;
        &:not(:last-child) {
          margin-right: 0;
        }
      }
      .cover {
        width: 100%;
      }
      .title {
        font-size: 0.9rem;
        font-weight: 800;
        padding: 1.8rem 0;
        text-align: center;
      }
      &:hover {
        .title {
          opacity: 0;
        }
        .mask {
          opacity: 1;
        }
      }
      .mask {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 1.5rem;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        background: #9205ab;
        width: 100%;
        height: 100%;
        border-radius: 28px;
        transition: all 0.8s;
        .head {
          font-size: 1.2rem;
          color: #fff;
          font-weight: 800;
          line-height: 2;
        }
        .desc {
          color: #fff;
          font-size: 0.7rem;
          font-weight: 600;
          line-height: 1.6;
          overflow: hidden;
          > p {
            color: #fff;
            margin-bottom: 1.2rem;
          }
        }
      }
    }
  }
`;

const Strategy = () => {
  return (
    <Section id="strategy" bgc="#f7f7f7">
      <ArrowUp width="18rem" pos={{ left: '-9rem', bottom: '2rem' }} />
      <ArrowUp width="3.8rem" pos={{ right: '9.69%', top: '3rem' }} />
      <ArrowUp type={4} width="20.2rem" pos={{ bottom: '-3rem', right: '2rem' }} />
      <Wrapper>
        <Title text={'“紫荆云”三大亮点'} />

        <div className="cards">
          {News.map((item) => (
            <div className="card" key={item.title}>
              <img src={item.cover} alt="图片" className="cover" />
              <p className="title">{item.title}</p>
              <div className="mask">
                <h2 className="head">{item.title}</h2>
                <div className="desc">
                  {Array.isArray(item.desc) ? item.desc.map((d) => <p key={d}>{d}</p>) : item.desc}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Wrapper>
    </Section>
  );
};

export default Strategy;
