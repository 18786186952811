import GFCImg from 'imgs/zj/team/gfc.png';
import ZGJImg from 'imgs/zj/team/zgj.png';
import LSSImg from 'imgs/zj/team/lss.png';

import QXYImg from 'imgs/zj/team/qxy.jpg';
import YSImg from 'imgs/zj/team/ys.png';
import LYUImg from 'imgs/zj/team/lyu.png';
import DYSImg from 'imgs/zj/team/dys.png';
import LYImg from 'imgs/zj/team/ly.png';
import ZYJImg from 'imgs/zj/team/zyj.png';
import WYYImg from 'imgs/zj/team/wyy.png';

const Profiles = [
  {
    name: '郭福昌',
    avator: GFCImg,
    bgColor: '#767676',
    titles: ['中国民办教育协会学前教育', '专业委员会创始理事长']
  },

  {
    name: '张光鉴',
    avator: ZGJImg,
    bgColor: '#c2bca2',
    titles: ['钱学森弟子、著名思维科学家', '“相似论”创立者']
  },
  {
    name: '仇学颖',
    avator: QXYImg,
    bgColor: '#242424',
    titles: ['代码星球创始人','牛津大学人工智能系博士']
  },
  {
    name: '杨帅',
    avator: YSImg,
    titles: ['宝贝计画创始人']
  },
  {
    name: '李姗姗',
    avator: LSSImg,
    titles: ['伊斯曼音乐学院音乐教育硕士', '中央音乐学院音乐教育学士']
  },

  {
    name: '刘宇',
    avator: LYUImg,
    titles: ['棋本易联合创始人']
  },
  {
    name: '杜雨莎',
    avator: DYSImg,
    titles: ['中国艺术研究院 舞编导师', '中央电视台《舞蹈世界》讲师']
  },
  {
    name: '刘扬',
    avator: LYImg,
    titles: ['火星人俱乐部创始人', '北京大学电子学硕士']
  },
  {
    name: '张宇瑾',
    avator: ZYJImg,
    titles: ['壹点壹滴教研与教学管理中心总经理', '北京市科协“创新创业”双创导师']
  },
  {
    name: '王营营',
    avator: WYYImg,
    titles: ['紫荆云师训服务总经理', '中国未来研究会教育分会理事']
  }
];

export default Profiles;
