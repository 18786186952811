/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from 'react';
import styled from 'styled-components';
const StyledWrapper = styled.ul`
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.5rem 0.5rem 0.5rem 0.5rem;
  background: #810187;
  z-index: 999;
  display: flex;
  border-bottom-right-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem;
  @media (max-width: 768px) {
    display: none;
  }
  li {
    position: relative;
    .link,
    .nav {
      display: block;
      font-size: 0.8rem;
      font-weight: 300;
      color: #fff;
      margin: 0 0.5rem;
      word-break: keep-all;
      padding-bottom: 0.4rem;
      &.curr {
        font-weight: 800;
        border-bottom: 2px solid #95007c;
      }
    }
    &:not(:last-child):after {
      content: '';
      display: block;
      position: absolute;
      top: 1px;
      right: 0;
      background: #fff;
      width: 1px;
      height: 0.7rem;
    }
  }
`;
const Links = [
  {
    title: '首页',
    id: 'banner'
  },
  {
    title: '紫荆云简介',
    id: 'intro'
  },
  // {
  //   title: '创新增长',
  //   id: 'growth'
  // },
  // {
  //   title: '市场机会',
  //   id: 'oppo'
  // },
  {
    title: '三大亮点',
    id: 'strategy'
  },
  {
    title: '十大课程',
    id: 'course'
  },
  {
    title: '教学系统',
    id: 'ai_box'
  },
  {
    title: '专家顾问',
    id: 'team'
  },
  // {
  //   title: '加入我们',
  //   id: 'invite'
  // },
  {
    title: '战略合作',
    id: 'coop'
  }
];
export default function Nav() {
  const handleClick = (evt) => {
    let currLink = evt.target;
    let id = currLink.getAttribute('href');
    let viewEle = document.querySelector(id);
    // 判断下兼容性
    if ('scrollIntoView' in document.body) {
      evt.preventDefault();
      if (viewEle) {
        viewEle.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }
      console.log({ currLink }, id);
    }
    [...document.querySelectorAll('#site_nav li>a.nav')].forEach((link) => {
      link.classList.remove('curr');
    });
    currLink.classList.add('curr');
    [...document.querySelectorAll('.block')].forEach((block) => {
      block.classList.remove('target');
    });
    viewEle.classList.add('target');
  };
  useEffect(() => {
    let { hash } = document.location;
    if (hash) {
      let navEle = document.querySelector(hash);
      if (navEle) {
        navEle.classList.add('curr');
      }
    } else {
      let firstLinkEle = document.querySelector('#site_nav li>a:first-child');
      firstLinkEle.classList.add('curr');
    }
  }, []);
  return (
    <StyledWrapper id="site_nav">
      {Links.map(({ title, id }) => {
        return (
          <li key={id}>
            <a onClick={handleClick} className="nav" href={`#${id}`}>
              {title}
            </a>
          </li>
        );
      })}
      <li>
        <a className="link" target="_blank" href={`https://jigou.zijing365.cn/`}>
          管理入口
        </a>
      </li>
      {/* <li>
        <a className="link" target="_blank" href={`https://jigou.zijing365.cn/#/certs/parent`}>
          教师上岗证书
        </a>
      </li> */}
    </StyledWrapper>
  );
}
