import React from 'react';
import styled from 'styled-components';
import TelImg from 'imgs/tel.png';
const Section = styled.section`
  background-color: ${({ bgc }) => (bgc ? bgc : '#343434')};
  .wrapper {
    max-width: 50rem;
    margin: 0 auto;
    padding: 1rem 0 1.2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .hotline {
      display: flex;
      flex-direction: column;
      .head {
        font-size: 0.6rem;
        color: #fff;
        padding-bottom: 0.3rem;
      }
      .line {
        width: 1.2rem;
        height: 2px;
        background: #9205ab;
        margin-bottom: 1rem;
      }
      .detail {
        display: flex;
        .logo {
          width: 2.3rem;
          height: 100%;
        }
        .time {
          margin-left: 0.7rem;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          > a {
            color: #fff;
            font-size: 1.1rem;
            font-weight: 800;
            text-decoration: none;
          }
          > time {
            font-size: 0.6rem;
            color: #959595;
          }
        }
      }
    }
    .copyright {
      color: #959595;
      font-size: 0.6rem;
      span {
        display: inline-block;
        margin-left: 0.8rem;
        color: #959595;
        a {
          color: #959595;
        }
      }
    }
    @media (max-width: 768px) {
      justify-content: center;
      .hotline .head,
      .hotline .line {
        display: none;
      }
    }
  }
`;

const Footer = () => {
  return (
    <>
      <Section key="footer_1">
        <div className="wrapper">
          <div className="hotline">
            <h2 className="head">服务热线</h2>
            <span className="line" />
            <div className="detail">
              <img src={TelImg} alt="电话LOGO" className="logo" />
              <div className="time">
                <a href="tel:400-666-7689">400-666-7689</a>
                <time>周一至周日 8:00 ~ 20:00</time>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Section key="footer_2" bgc="#202020">
        <div className="wrapper">
          <div className="copyright">
            Copyright © All Rights Reserved
            {/* <span>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802030868"
              >
                京公网安备 11010802030868号
              </a>
            </span> */}
            <span>
              <a rel="noopener noreferrer" target="_blank" href="https://beian.miit.gov.cn/">
                京ICP备2023012516号
              </a>
            </span>
          </div>
        </div>
      </Section>
    </>
  );
};

export default Footer;
