import React from 'react';
import styled from 'styled-components';

import Section from './__Section';
import Title from './__Title';
import ArrowUp from './__ArrowUp';

import AsideImage from 'imgs/zj/aside.what.png';
import DotImage from 'imgs/zj/dot.png';
const Wrapper = styled.div`
  position: relative;
  max-width: 96rem;
  margin: 0 auto;
  padding: 4.05rem 0 4.7rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  .content {
    display: flex;
    align-items: center;
    z-index: 1;
    padding: 3.8rem 0.4rem 0 0.4rem;

    .aside {
      width: 24.3rem;
      margin-right: 4rem;
    }
    .intro {
      position: relative;
      width: 27.9rem;
      .dot {
        position: absolute;
        width: 3.4rem;
        &.top {
          left: 0;
          top: -4.4rem;
        }
        &.btm {
          right: 0;
          bottom: -4rem;
          transform: rotate(180deg);
        }
      }
      > p {
        line-height: 1.8rem;
        font-size: 0.8rem;
        margin-bottom: 1rem;
      }
    }
    @media (max-width: 768px) {
      flex-direction: column-reverse;
      .intro {
        margin: 2rem 0 5rem 0;
      }
      .aside {
        width: 100%;
        margin-right: 0;
        transform: scale(1.3);
      }
    }
  }
`;

const Intro = () => {
  return (
    <Section id="intro">
      <ArrowUp width="7.6rem" pos={{ left: '6.7%', top: '8.4rem' }} />
      <ArrowUp type={4} width="22.9rem" pos={{ bottom: '-5rem', right: '-3rem' }} />
      <Wrapper>
        <Title text={'什么是“紫荆云”？'} />
        <div className="content">
          <img className="aside" src={AsideImage} alt="紫荆工厂配图" />
          <div className="intro">
            <img src={DotImage} alt="上面逗号" className="dot top" />
            <img src={DotImage} alt="下面逗号" className="dot btm" />
            <p>“紫荆云”，全称“紫荆儿童成长云平台”，是专门服务 3-10 岁儿童的素质教育互联网平台。</p>
            <p>
            “紫荆云”，专注研究儿童素质教育，让全国各地的孩子都能享受和北京孩子同步的素质教育是我们的初心。我们始终坚持以孩子为中心，以素质教育为本，专注儿童成长，追求卓越品质。
            </p>
          </div>
        </div>
      </Wrapper>
    </Section>
  );
};

export default Intro;
