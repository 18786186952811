import React from 'react';
import styled from 'styled-components';
import Section from './__Section';
import Title from './__Title';
import ArrowUp from './__ArrowUp';

import AsideImage from 'imgs/zj/aside.box.png';

const Wrapper = styled.div`
  position: relative;
  max-width: 96rem;
  margin: 0 auto;
  padding: 4rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .sys {
    .tip {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 2.7rem;
      margin-bottom: 2.9rem;
      > p {
        color: #424242;
        font-size: 0.8rem;
        line-height: 1.6rem;
      }
    }
    .box {
      width: 35.9rem;
    }
  }
`;

const AIBox = () => {
  return (
    <Section id={'ai_box'} bgc="#f7f7f7">
      <ArrowUp width="24.5rem" pos={{ left: '3.1rem', bottom: '-7.4rem' }} />
      <ArrowUp width="4.1rem" pos={{ top: '40%', right: '23rem' }} />
      <ArrowUp type={4} width="15.8rem" pos={{ right: '-2rem', bottom: '2.8rem' }} />

      <Wrapper>
        <Title text={'“紫荆云”智能教学系统'} />
        <div className="sys">
          <div className="tip">
            <p>“紫荆云”，依托强大的技术实力，搭建儿童素质教育智能教学系统，课程锁定云端，</p>
            <p>现场通过遥控器控制机顶盒，把优质的素质教育内容带到各个教育机构、带给每个孩子。</p>
          </div>
          <img src={AsideImage} alt="智能盒子配图" className="box" />
        </div>
      </Wrapper>
    </Section>
  );
};

export default AIBox;
