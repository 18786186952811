import ST1Img from 'imgs/zj/st/st.1.png';
import ST2Img from 'imgs/zj/st/st.2.png';
import ST3Img from 'imgs/zj/st/st.3.png';
import IconB from 'imgs/zj/icon.book.png';
import IconD from 'imgs/zj/icon.daimond.png';
import IconDesk from 'imgs/zj/icon.desktop.png';
const News = [
  {
    title: '亮点之一  课程赋能',
    desc:
      '围绕体育、美育、音乐、编程、口才、戏剧等细分领域，遴选全国优质的素质教育课程，输送到全国各个教育机构，让全国各地的孩子都能享受和北京孩子同步的素质教育。',
    cover: ST1Img,
    icon: IconB
  },
  {
    title: '亮点之二  师资赋能',
    cover: ST2Img,
    icon: IconDesk,
    desc: [
      '核心围绕三点，赋能各教育机构老师。',
      '第一，培训赋能，重点培训老师有关课程教法。第二，资源赋能，平台提供有关课程的所有教学资源，包括但不限于视频、图片、课件、PPT等，非常方便。第三，专家赋能，课程品牌方提供一位专业老师，把课程一些重点、难点，亲自讲一遍，以录播的方式，随时支持一线老师，或供老师反复学习，或由老师直接在课堂播放。'
    ]
  },
  {
    title: '亮点之三  运营赋能',
    cover: ST3Img,
    icon: IconD,
    desc:
      '素质教育进校园，一定要贯彻“普及、普惠、优质，安全”四原则；素质教育进校园，一定要追求孩子乐学、老师乐教、家长乐意；素质教育进校园，一定要围绕孩子学习成果交付、家长满意度跟踪等运营重点来深度赋能各教育机构。'
  }
];

export default News;
