import Card1Img from 'imgs/zj/course/cj.png';
import Card2Img from 'imgs/zj/course/hh.png';
import Card3Img from 'imgs/zj/course/bb.png';
import Card4Img from 'imgs/zj/course/hy.png';
import Card5Img from 'imgs/zj/course/sw.png';
import Card6Img from 'imgs/zj/course/qby.png';
import Card7Img from 'imgs/zj/course/mia.png';
import Card8Img from 'imgs/zj/course/gs.png';
import Card9Img from 'imgs/zj/course/xx.png';
import Card11Img from 'imgs/zj/course/bs.png';
import Card12Img from 'imgs/zj/course/yy.png';
import Card13Img from 'imgs/zj/course/xl.png';
import Card14Img from 'imgs/zj/course/sjzl.png';


const Course = [
  {
    title: '超级口才课程',
    desc: '生活中说出彩、舞台上演出彩',
    items: [
      '提升表达力，培养表现力',
      '课课有成果，成长看得见',
      '紫荆云专家团队倾情打造'
    ],
    cover: Card1Img
  },
  {
    title: '逻辑启蒙课程',
    desc: '让孩子的思维卓尔不群',
    items: [
      '游戏级互动教学，寓教于乐，孩子爱学',
      '课程模块化，目标专题化，情境趣味化',
      '全面提升幼儿数理思维能力和专注力'
    ],
    cover: Card2Img
  },
  {
    title: '宝贝计画创意美术课程',
    desc: '让创意点亮生活',
    items: [
      '想象力与技法结合，孩子周周有作品',
      '多元化的课程内容，全面增长知识',
      '提高孩子绘画技巧，点亮孩子想象力'
    ],
    cover: Card3Img
  },
  {
    title: '花样数独课程',
    desc: '喜欢数独的孩子数学都不会差',
    items: [
      '开启数学兴趣的互动教学，奇趣无穷',
      '动画故事贯穿课程，激发孩子学习乐趣',
      '搭配花样数独盒子，快乐实操，方便收纳'
    ],
    cover: Card4Img
  },
  {
    title: '思维实验室课程',
    desc: '来自以色列的思维游戏',
    items: [
      '18套引人入胜的思维游戏棋为教学载体',
      '科学的教育体系，符合孩子身心发展特点',
      '掌握十大思维模型，培养孩子综合思维能力'
    ],
    cover: Card5Img
  },
  {
    title: '棋本易围棋课程',
    desc: '让学围棋更容易',
    items: [
      '趣味故事引入围棋知识点，享受围棋乐趣',
      '游戏级教学互动，提升孩子的参与感',
      '多学科教育资源支持和AI智能技术相结合'
    ],
    cover: Card6Img
  },
  {
    title: 'MIA舞蹈课程',
    desc: '快乐中舞动美好童年',
    items: ['独特分龄分版专业课程体系', '情景表演式授课，标准化输出', '“连动学习+多元游戏”模式'],
    cover: Card7Img
  },
  {
    title: '全优全能课程',
    desc: '让你的孩子具备入学七大能力',
    items: [
      '幼儿个性化课程体系，让孩子全面发展',
      '真人和动画相结合，趣味性地课堂体验',
      '循序渐进满足大班孩子学习与发展需要'
    ],
    cover: Card8Img
  },
  {
    title: '火星人科学家课程',
    desc: '让科学成为乐趣',
    items: [
      '项目设计、探究学习，培养严谨的科学思维',
      '启发教学、兴趣引导，培养解决问题的能力',
      '多维内容、体验教学，建构完整的科学认知'
    ],
    cover: Card9Img
  },
  {
    title: '变色龙编程启蒙课程',
    desc: '牛津大学博士带你学编程',
    items: [
      '线上+线下游戏教学，激发孩子学习兴趣',
      '丰富的故事场景，提高孩子编程创作热情',
      '趣味十足的配套材料，助力孩子玩转编程'
    ],
    cover: Card11Img
  },
  {
    title: '音乐启蒙课程',
    desc: '让孩子学会用音乐去表达',
    items: [
      '动画主题生动形象，激发孩子学习积极性',
      '音乐识别精准纠错，还原线下一对一教学',
      '文化常识+演唱演奏，提升创造力和艺术感'
    ],
    cover: Card12Img
  },
  {
    title: '心力UP训练课程',
    desc: '全面提升孩子的抗挫力',
    items: [
      '让孩子相信自己有能力学会一切',
      '帮助孩子将负能量转化成正能量',
      '提升孩子应对挑战和困难的能力'
    ],
    cover: Card13Img
  },
  {
    title: '世界之旅体验营',
    desc: '让孩子足不出户领略世界风情',
    items: [
      '课程内容多元化，激发孩子学习兴趣',
      'PBL教学法，挖掘孩子探索潜能',
      '教学成果可视化，引导孩子自信展示'
    ],
    cover: Card14Img
  }
];

export default Course;
