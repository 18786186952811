import { useEffect } from 'react';

export default function MTA({ sid }) {
  useEffect(() => {
    console.log(sid);
    window._mtac = {};
    const mta = document.createElement('script');
    mta.src = '//pingjs.qq.com/h5/stats.js?v2.0.4';
    mta.setAttribute('name', 'MTAH5');
    mta.setAttribute('sid', sid);
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(mta, s);
  }, [sid]);
  return null;
}
