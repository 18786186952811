import React from 'react';
import styled from 'styled-components';
import Courses from 'RootCommon/course';
import Section from './__Section';
import ArrowUp from './__ArrowUp';
import Title from './__Title';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 3rem 0 2.3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content {
    display: flex;
    align-items: flex-start;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    width: 55.4rem;
    margin-top: 3.4rem;

    .course {
      width: 10.6rem;
      margin-bottom: 3.5rem;
      margin-right: .6rem;
      &:hover .cover {
        box-shadow: 0px -9px 14px 8px rgba(56, 0, 95, 0.08);
        transform: scale(1.05);
      }
      &:nth-child(5n) {
        margin-right: 0;
      }
      .cover {
        width: 100%;
        border-radius: 15px;
        margin-bottom: 0.8rem;
        transition: all 0.5s;
      }
      > h2 {
        font-size: 0.8rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
        color: #4c4948;
      }
      > h3 {
        font-size: 0.7rem;
        margin-bottom: 0.9rem;
        color: #808080;
      }
      ul {
        li {
          width: 200%;
          white-space: nowrap;
          overflow: hidden;
          font-size: 1rem;
          transform-origin-x: 0;
          transform: scale(0.5);
          padding-left: 1.2rem;
          line-height: 1.3;
          position: relative;
          color: #4c4948;
          &:before {
            position: absolute;
            left: 0;
            top: 0;
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-top: 0.6rem solid transparent;
            border-bottom: 0.6rem solid transparent;
            border-left: 0.6rem solid #edbc04;
          }
        }
      }
    }
    @media (max-width: 768px) {
      width: 30rem;
      .course {
        width: 14.2rem;
        > h2 {
          font-size: 1rem;
        }
        > h3 {
          font-size: 0.8rem;
        }
        ul li {
          font-size: 0.6rem;
        }
      }
    }
  }
`;

const Course = () => {
  function createMarkup(item) {
    return { __html: item };
  }
  return (
    <Section id="course">
      <ArrowUp width="24.5rem" pos={{ right: '2.24%', bottom: '45%' }} />
      <ArrowUp width="10.5rem" pos={{ left: '3.18%', bottom: '5%' }} />
      <ArrowUp type={4} width="6.4rem" pos={{ left: '11.2%', top: '11.5rem' }} />

      <Wrapper>
        <Title text={'“紫荆云”优质素质教育课程'} />
        <div className="content">
          {Courses.map((c) => {
            const { title, desc, items, cover } = c;
            return (
              <div key={title} className="course">
                <img className="cover" src={cover} alt="课程封面" />
                <h2>{title}</h2>
                <h3>{desc}</h3>
                <ul>
                  {items.map((item) => {
                    return <li key={item} dangerouslySetInnerHTML={createMarkup(item)}></li>;
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </Wrapper>
    </Section>
  );
};

export default Course;
