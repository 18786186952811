import React from 'react';
import styled from 'styled-components';
import Section from './__Section';
import Title from './__Title';
import ArrowUp from './__ArrowUp';

import ImgLogos from 'imgs/zj/logos.png';

const Wrapper = styled.div`
  position: relative;
  max-width: 40rem;
  margin: 0 auto;
  padding: 4rem 0 5.2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .logos {
    margin-top: 4.6rem;
    width: 46rem;
  }
  @media (max-width: 768px) {
    .logos {
      width: 90%;
    }
  }
`;

const Cooperation = () => {
  return (
    <Section id={'coop'} bgc="#f7f7f7">
      <ArrowUp type={4} width="14rem" pos={{ left: '-1rem', bottom: '5%' }} />
      <ArrowUp type={4} width="14rem" pos={{ right: '-1rem', bottom: '2%' }} />

      <Wrapper>
        <Title text={'“紫荆云” 战略合作伙伴'} />

        <img src={ImgLogos} alt="合作伙伴" className="logos" />
      </Wrapper>
    </Section>
  );
};

export default Cooperation;
