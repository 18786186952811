import React from 'react';
import styled from 'styled-components';
import Section from './__Section';
import ArrowUp from './__ArrowUp';
import Nav from './__Nav';

import LogoImg from 'imgs/zj/logo.top.png';
import AsideImage from 'imgs/zj/aside.banner.girl.png';
import GroupImage from 'imgs/zj/banner.arrow.up.group.png';
const StyledSection = styled(Section)`
  background-image: url(${GroupImage});
  background-size: 25.2rem auto;
  background-repeat: no-repeat;
  background-position: 0 4rem;
`;

const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 6.6rem 0 5.4rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 55.4rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  .logo {
    position: absolute;
    left: 0;
    top: 0.5rem;
    width: 8.7rem;
    @media (max-width: 768px) {
      top: 10px;
      right: 10px;
      left: auto;
    }
  }
  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 2;
    .head {
      font-size: 2.2rem;
      font-weight: 800;
      margin-bottom: 1rem;
      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        > span {
          line-height: 1.5;
        }
      }
      > span {
        color: #fff;
      }
    }
    .desc {
      background: #e5a537;
      border-radius: 8px;
      color: #9205ab;
      font-size: 1.2rem;
      line-height: 1.3;
      padding: 0.3rem 1rem;
    }
    .list {
      font-size: 0.8rem;
      padding: 1rem 0;
      margin-top: 1rem;
      line-height: 1.8;
      list-style-type: none;
      > li {
        color: #fff;
        position: relative;
        padding-left: 0.8rem;
        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 0.3rem;
          height: 0.3rem;
          border-radius: 50%;
          background-color: #e5a537;
        }
      }
      .title_li{
        padding-left: 0;
        &:before{
          display:none;
        }  
      }
    }
  }
  .aside {
    z-index: 2;
    width: 14.2rem;
    @media (max-width: 768px) {
      align-self: flex-end;
      margin-top: -6rem;
    }
  }
`;

const Banner = () => {
  return (
    <StyledSection id={'banner'} bgc={'#810187'}>
      <ArrowUp type={1} width="12.8rem" pos={{ top: '2.6rem', left: '50%' }} />
      <ArrowUp type={1} width="4.5rem" pos={{ bottom: '45%', right: '7.5rem' }} />

      <ArrowUp type={2} width="33.6rem" pos={{ right: '11.2rem', bottom: '-2rem' }} />
      <Wrapper>
        <Nav />
        {/* <img className="arrows" src={GroupImage} alt="箭头组合" /> */}
        <img src={LogoImg} alt="紫荆儿童成长云平台" className="logo" />
        <div className="header">
          <h1 className="head">
            <span>紫荆云</span> <span style={{marginLeft:15}}>专注研究儿童素质教育</span>
          </h1>
          <h2 className="desc">个性发展 扬长补短</h2>
          <ul className="list">
            <li className='title_li'>“紫荆云”，矢志让全国各地的孩子都能享受跟北京孩子同步的素质教育</li>
            <li>如果你缺优质的素质教育课程，请找“紫荆云”</li>
            <li>如果你的老师需要专业落地培训，请找“紫荆云”</li>
            <li>如果你需要有人手把手带你运营素质教育项目，请找“紫荆云”</li>
          </ul>
        </div>
        <img className="aside" src={AsideImage} alt="Banner配图" />
      </Wrapper>
    </StyledSection>
  );
};

export default Banner;
